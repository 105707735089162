import * as type from "../constantsStore";

const initalState = {
    userData: null,
    isAuth: false,
    message401: "",
};

const authReducer = (state = initalState, action) => {
    switch (action.type) {
        case type.SET_AUTH_DATA:
            return { ...state, userData: action.payload, isAuth: true };
        case type.UNSET_AUTH_DATA:
            return { initalState };
        case type.DATA_ERROR_401:
            return { ...state, message401: action.payload };
        default:
            return state;
    }
};
export default authReducer;
