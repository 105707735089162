import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import * as type from "../constantsStore";

const initalState = {
    crmContract: {
        agency_uuid: null,
        text_search: "",
        type: null,
        status: null,
        to_date: "",
        from_date: "",
        row: ROW_DEFAULT,
        page: PAGE_DEFAULT,
    },
};

const searchCrmContractReducer = (state = initalState, action) => {
    switch (action.type) {
        case type.SEARCH_CRM_CONTRACT:
            return { ...state, crmContract: action.payload };
        default:
            return state;
    }
};
export default searchCrmContractReducer;
