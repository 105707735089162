import { PAGE_DEFAULT, ROW_DEFAULT } from 'constants/global';
import * as type from '../constantsStore';

const initalState = {
    typeDoc: {
        text_search: '',
        row: ROW_DEFAULT,
        page: PAGE_DEFAULT,
        category: null,
    },
};

const searchTypeDocReducer = (state = initalState, action) => {
    switch (action.type) {
        case type.SEARCH_TYPE_DOC:
            return { ...state, typeDoc: action.payload };
        default:
            return state;
    }
};
export default searchTypeDocReducer;
