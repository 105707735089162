import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import * as type from "../constantsStore";

const initalState = {
    crmInfo: {
        tax_code: "",
        text_search: "",
        row: ROW_DEFAULT,
        page: PAGE_DEFAULT,
    }
};

const searchCrmInfoReducer = (state = initalState, action) => {
    switch (action.type) {
        case type.SEARCH_CRM_INFO:
            return { ...state, crmInfo: action.payload };
        default:
            return state;
    }
};
export default searchCrmInfoReducer;
