
import React, { lazy, Suspense, useEffect } from 'react';

import { AppProvider } from 'context/AppProvider';
import { SocketProvider } from 'context/SocketProvider';
import PrimeReact from 'primereact/api';
import PageProgress from 'react-page-progress'
import { Provider } from "react-redux";
import store from "app/store";

import { KeycloakProvider } from "@react-keycloak/web";
import keycloak from './keycloak';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from "components/ErrorBoundary/ErrorFallback";
import { QueryClient, QueryClientProvider, useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import EmptyPage from 'components/EmptyPage/EmptyPage';

// ** Styles
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/style/layout.scss';


const WrapApp = (props) => {
    const LazyApp = lazy(() => import('./App'));
    const queryClient = new QueryClient()

    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                staleTime: 10000,
                keepPreviousData: true,
                // cacheTime: 0,
                refetchOnWindowFocus: false,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { reset } = useQueryErrorResetBoundary()

    PrimeReact.ripple = true;
    PrimeReact.autoZIndex = true;

    return (
        <div className="WrapApp">
            <ErrorBoundary
                onReset={reset}
                FallbackComponent={ErrorFallback}
            >
                <SocketProvider>
                    <Provider store={store}>
                        <AppProvider>
                            <KeycloakProvider
                                keycloak={keycloak}
                            >
                                <QueryClientProvider client={queryClient}>
                                    <PageProgress height={4} className="PageProgress" />
                                    <Suspense fallback={<EmptyPage />}>
                                        <LazyApp />
                                        {/* <App /> */}
                                    </Suspense>
                                    <ReactQueryDevtools initialIsOpen />
                                </QueryClientProvider>
                            </KeycloakProvider>
                        </AppProvider>
                    </Provider>

                </SocketProvider>
            </ErrorBoundary >


        </div >
    )
}
export default (WrapApp);

