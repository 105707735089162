import { combineReducers } from "redux";
import authReducer from "./authReducer";
import searchTypeDocReducer from "app/searchReducers/searchTypeDocReducer";
import searchCrmInfoReducer from "app/searchReducers/searchCrmInfoReducer";
import searchCrmContractReducer from "app/searchReducers/searchCrmContractReducer";
export default combineReducers({
    authReducer,
    searchTypeDocReducer,
    searchCrmInfoReducer,
    searchCrmContractReducer
});
