import { SocketContext } from 'context/SocketContext';
import { useEffect, useState } from 'react';
import { io } from "socket.io-client";
const windowEnvConfig = window['runConfig'];

export const SocketProvider = (props) => {
    const { children } = props;
    const [objSocket, setObjSocket] = useState(null);
    const REACT_APP_SOCKET = windowEnvConfig.REACT_APP_SOCKET_URL;
    useEffect(() => {
        const newSocket = io(REACT_APP_SOCKET);
        setObjSocket(newSocket);
        // eslint-disable-next-line
    }, [])
    return (
        <SocketContext.Provider
            value={{
                objSocket
            }}>
            {children}
        </SocketContext.Provider>
    );
};
