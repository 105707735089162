import React from 'react';

// ** Styles
import './loading.scss'
import LoaderIcon from './LoaderIcon';

const Loader = () => {
    return (
        <div id="loader"
            className="Loader p-d-flex p-ai-center p-jc-center p-as-center vh--100">
            <LoaderIcon/>
        </div>
    )
}

export default Loader

