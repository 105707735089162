import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

// ** Styles
const LoaderIcon = () => {
    return (
        <ProgressSpinner
            style={{ width: '70px', height: '70px' }}
            className="position-center opacity-100"
            strokeWidth="3"
            animationDuration=".5s"
        />
    );
};

export default LoaderIcon;
