export const RANGER_DATE_CALENDAR = "1900:2100";

export const PAGES = [
    5, 10, 20, 50, 100
];

export const MAX_FILE_SIZE_DEFAULT = 20;

export const ROW_DEFAULT = 10;
export const PAGE_DEFAULT = 1;

export const VI_LANG = 'vi';
export const EN_LANG = 'en';


export const ARR_TYPE_FILE = [
    {
        label: "File Excel (.xlsx)",
        value: "excel"
    },
    {
        label: "File PDF (.pdf)",
        value: "pdf"
    }
]

export const SYSTEM_APP_SIGN = 1;
export const SYSTEM_APP_ECONTRACT = 2;



